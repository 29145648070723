import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { Router, Event, NavigationEnd } from "@angular/router";
import { DataService } from '../../services/data.service';
import { WINDOW } from '@ng-toolkit/universal';
import { NONE_TYPE } from '@angular/compiler/src/output/output_ast';

declare var $: any;

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  isMobileOpen = false;
  hoverLetters = [];
  word_to_show ="";
  constructor(@Inject(WINDOW) private window: Window, public router: Router, public dataService: DataService) { 
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.checkColorsAndSize();
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkColorsAndSize();
  }

  checkColorsAndSize(){
    this.dataService.setHeight();
    setTimeout(() => {
      if(this.window.matchMedia('(min-width: 960px)').matches){
        if(this.router.url.includes('')){
          this.dataService.setNavBarColorWhite();
          this.dataService.hideHiddenText();
          this.dataService.removeBreakLogoProjects();
        } 
        if(this.router.url.includes('feedback')){
          this.dataService.setNavBarColorBlack();
          this.dataService.hideHiddenText();
          this.dataService.showHiddenText("f");
          this.dataService.removeBreakLogoProjects();
          this.dataService.setBodyGrey();
        } 
        else if(this.router.url.includes('projects')){
          this.dataService.setNavBarColorBlack();
          this.dataService.hideHiddenText();
          this.dataService.breakLogoProjects();
          this.dataService.setBodyGrey();
        }
        else if(this.router.url.includes('project')){
          this.dataService.setNavBarColorBlack();
          this.dataService.hideHiddenText();
          this.dataService.breakLogoProjects();
          this.dataService.setBodywhitee();
        }
        else if(this.router.url.includes('contacts')){
          this.dataService.setNavBarColorWhite();
          this.dataService.hideHiddenText();
          this.dataService.showHiddenText("dash");
          this.dataService.removeBreakLogoProjects();
          this.dataService.setBodyBlack();
        }
        else if(this.router.url.includes('about')){
          this.dataService.setNavBarColorWhite();
          this.dataService.hideHiddenText();
          this.dataService.showHiddenText("a");
          this.dataService.removeBreakLogoProjects();
          this.dataService.setBodyBlack();
        }
      } else {
        if(this.router.url.includes('feedback')){
          this.dataService.setNavBarColorWhite();
          this.dataService.hideHiddenText();
          this.dataService.removeBreakLogoProjects();
          this.dataService.setBodyBlack();
        } 
        else if(this.router.url.includes('projects')){
          this.dataService.setNavBarColorBlack();
          this.dataService.hideHiddenText();
          this.dataService.removeBreakLogoProjects();
          this.dataService.setBodyGrey();
        }
        else if(this.router.url.includes('project')){
          this.dataService.setNavBarColorBlack();
          this.dataService.hideHiddenText();
          this.dataService.removeBreakLogoProjects();
          this.dataService.setBodywhitee();
        }
        else if(this.router.url.includes('contacts')){
          this.dataService.setNavBarColorWhite();
          this.dataService.hideHiddenText();
          this.dataService.removeBreakLogoProjects();
          this.dataService.setBodyBlack();
        }
        else if(this.router.url.includes('about')){
          this.dataService.setNavBarColorWhite();
          this.dataService.hideHiddenText();
          this.dataService.removeBreakLogoProjects();
          this.dataService.setBodyBlack();
        }
      }
    });
    setTimeout(() => {
      $(".page_content, .hidden_text").addClass("visible");
    }, 1500);
  }

  ngOnInit() {
  }

  checkMenuState(){
    if(this.isMobileOpen){
      setTimeout(() => {
        $("#menu").css({
        
          'background-color':"black",
          top:0
        });
      }, 500);
     
      $("#menu").css({
        'display':"block"
      });

    
     
    }else {
      $("#menu").css({
        
        top: "-"+this.dataService.getSize(),
        
      });
      setTimeout(() => {
        $("#menu").css({
          'background-color':"transparent",
          'display':"none"
        });
      }, 1200);
    }
  }
}
