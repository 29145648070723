import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

const httpOptions = {
  // headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class EventosService {

  constructor(public http: HttpClient) { }

  getEventos(){
    return this.http.get(environment.baseUrl + '/projects/getProjects.php', httpOptions);
  }

  getCategories(){
    return this.http.get(environment.baseUrl + '/categories/getCategories.php', httpOptions);
  }
}
