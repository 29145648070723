import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { AboutComponent } from './components/about/about.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { ProjectComponent } from './components/project/project.component';
import { ContactsComponent } from './components/contacts/contacts.component';


const routes: Routes = [
  {path: '', component: HomeComponent},
  {
    path: 'feedback',
    component: FeedbackComponent,
    data:{title:'Feedback'}
  },
  {
    path: 'about',
    component: AboutComponent,
    data:{title:'About'}
  },
  {
    path: 'project/:url',
    component: ProjectComponent
   
  },
  {
    path: 'projects', 
    component: ProjectsComponent,
    data:{title:'Projects'}
  },
  {
    path: 'home',
    component: HomeComponent,
    data:{title:'Home'}
  },
  {
    path: 'contacts', 
    component: ContactsComponent,
    data:{title:'Contacts'}
  }
];

// const routes: Routes = [
//   {path: 'fpa/frontend', component: HomeComponent},
//   {path: 'fpa/frontend/feedback', component: FeedbackComponent},
//   {path: 'fpa/frontend/about', component: AboutComponent},
//   {path: 'fpa/frontend/project/:url', component: ProjectComponent},
//   {path: 'fpa/frontend/projects', component: ProjectsComponent},
//   {path: 'fpa/frontend/contacts', component: ContactsComponent}
// ];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})

export class AppRoutingModule { }





