import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import $ from "jquery";
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(public dataService: DataService) { }

  ngOnInit() {
  this.dataService.setNavBarColorWhite();


  var imagesmobile=new Array('../../../assets/imagens/about-foto2-mobile.png','../../../assets/imagens/about-foto3-mobile.png','../../../assets/imagens/about-foto4-mobile.png');
  var nextimagemobile=0;

  var images=new Array('../../../assets/imagens/about-foto2.png','../../../assets/imagens/about-foto3.png','../../../assets/imagens/about-foto4.png');
  var nextimage=0;


  if(window.matchMedia("(min-width: 960px)").matches)
  {
    doSlideshow();
    console.log("MERDA");
  }
  else{
    doSlideshowMobile();
  }
  

  function doSlideshowMobile(){
    if(nextimagemobile>=imagesmobile.length){nextimagemobile=0;}
    $('#about')
    .css('background-image','url("'+imagesmobile[nextimagemobile++]+'")')
    .fadeIn(500,function(){
        setTimeout(doSlideshowMobile,9000);
    });
  }

  function doSlideshow(){
    if(nextimage>=images.length){nextimage=0;}
    $('#about')
    .css('background-image','url("'+images[nextimage++]+'")')
    .fadeIn(500,function(){
        setTimeout(doSlideshow,9000);
    });
  }



     


    
  }
  }

  


