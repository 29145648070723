import { Component, OnInit, Inject } from '@angular/core';
import { DataService } from '../../services/data.service';
import { EventosService } from '../../services/eventos.service';
import { WINDOW } from '@ng-toolkit/universal';
declare var $: any;

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  loadingProjects = true;
  selectedCategory = 0;
  constructor(@Inject(WINDOW) private window: Window, 
    public dataService: DataService,
    public eventosService: EventosService) { }

  ngOnInit() {
 
    $('html, body').animate({scrollTop:0}, 'slow');
    if(this.dataService.getProjects() == null){
      this.getProjects();
    }else{
      this.loadingProjects = false;
    }
    if(this.dataService.getCategories() == null){
      this.getCategories();
    }else{
      this.loadingProjects = false;
    }
  }
  
  getProjects(){
    this.eventosService.getEventos().subscribe(projects => {
        this.dataService.setProjects(projects);
        this.getCategories();
    });
  }

  getCategories(){
    this.eventosService.getCategories().subscribe(categories=>{
      this.dataService.setCategories(categories);
      this.loadingProjects = false;
    });
  }

  checkProjectVisibility(){
    if(this.selectedCategory == 0){
      return true;
    }
    const selectedCategoryObj = this.dataService.getCategories()[this.selectedCategory-1];
    this.dataService.getProjects().forEach(project => {
      let projectHasCategory = false;
      for (let it = 0; it < project['categories'].length; it++) {
        const categoria = project['categories'][it];
        if(categoria.id == selectedCategoryObj.id){
          projectHasCategory = true;
          break;
        }
      }
      if(projectHasCategory){
        project.visible = true;
      } else {
        project.visible = false;
      }
    });
  }

  checkVisibility(project){
    if(this.selectedCategory == 0){
      return true;
    }
    if(project.visible){
      return true;
    }
    return false;
  }

  setCategory(i){
    $(".project_desktop").addClass('hidden');
    setTimeout(() => {
      this.selectedCategory = i;
      this.checkProjectVisibility();
      setTimeout(() => {
        $(".project_desktop").addClass('hidden');  
        setTimeout(() => {
          $(".project_desktop").removeClass('hidden');  
        }, 10);
      }, 10);
       
    }, 1000);
  }

  getCoverImg(project){
    // if (window.matchMedia('(min-width: 960px)').matches) {
    //   return this.dataService.baseUrl+'/imagens/'+project.background.file;
    // } else {
    //   return this.dataService.baseUrl+'/imagens/'+project.background_mobile.file;
    // }
      return this.dataService.baseUrl+'/imagens/'+project.background.file;
  }
  
}
