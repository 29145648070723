import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { DataService } from '../../services/data.service';
import { WINDOW } from '@ng-toolkit/universal';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {

  constructor(@Inject(WINDOW) private window: Window, 
    public dataService: DataService,
  ) { }

  ngOnInit() {
  } 

  social_media(link){
    this.window.open( link , "_blank"); 
  }
}
