import { Component, OnInit, Inject } from '@angular/core';
import { DataService } from '../../services/data.service';
import { EventosService } from '../../services/eventos.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";
import * as AOS from 'aos';
import * as sal from 'sal.js';
import { WINDOW } from '@ng-toolkit/universal';


declare var $: any;

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {

  loadingProjects = true;
  project = null;
  url = null;
  constructor(@Inject(WINDOW) private window: Window, 
    public dataService: DataService,
    public eventosService: EventosService,
    public route: ActivatedRoute,
    public router: Router) { }

    ngOnInit() {
      sal({});


      $("*").removeClass("aos-animate");
      AOS.init();
   
    $('html,body').animate({scrollTop:0}, 'slow');
    this.route.params.subscribe(params => {
      this.url = params['url'];
    });
    if(this.dataService.getProjects() == null){
      this.getProjects();
    }else{
      this.getPRojectByURL();
      this.loadingProjects = false;
    }
    this.dataService.setNavBarColorWhite();
  }

  getProjects(){
    this.eventosService.getEventos().subscribe(projects => {
      this.dataService.setProjects(projects);
      //LINHA ADICIONADA
      this.dataService.processProjects();
      this.loadingProjects = false;
      this.getPRojectByURL();
    });
  }

  getPRojectByURL(){
    let exists = false;
    for (let it = 0; it < this.dataService.getProjects().length; it++) {
      const projectCandidate = this.dataService.getProjects()[it];
      if(projectCandidate.url == this.url){
        this.project = projectCandidate;
        exists = true;
        break;
      }
    }
    if(!exists){
      this.router.navigate(['/']);
      return;  
    }
    if(!Array.isArray(this.project.variables)){
      this.project.variables = this.project.variables != "" ? JSON.parse(this.project.variables) : [];
    }
  }

  getDivWidth(img){
    if(this.window.matchMedia('(min-width: 960px)').matches){
      if(img.class == 'full'){
        return '100%';  
      } else if(img.class == 'half'){
        return '50%';
      }  else if(img.class == 'third'){
        return '33.33%';
      }  else if(img.class == 'two_thirds'){
        return '66.66%';
      }
    } 
    else {
      return '100%';
    }
  }

  getDescription(desc){
    return desc.replace(/\n/ig, '<br>');
  }

  getPrizes(pri){
    return pri.replace(/\n/ig, '<br>');
  }
  

  getMargin(img){
    if(this.window.matchMedia('(min-width: 960px)').matches){
    }
    else {
      return '0%';
    }
  }
}
