import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { EventosService } from '../../services/eventos.service';
import { DataService } from '../../services/data.service';
import { Router } from "@angular/router";

import 'hammerjs';
import { WINDOW } from '@ng-toolkit/universal';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(@Inject(WINDOW) private window: Window, 
    public router: Router, 
    public eventosService: EventosService, 
    public dataService: DataService) { }

  loadingProjects = true;
  projects = [];
  coverProject = null;
  feedbackProject = null;
  projectsProject = null;
  contactsProject = null;
  aboutProject = null;
  actualView = 0;
  totalViews = 4;
  inAnimation = false;

  myInterval=null;
  isFirst = true;

  @HostListener('mousewheel', ['$event']) onMousewheel(event) {
    if(!this.window.matchMedia('(min-width: 960px)').matches) {
      if(!this.inAnimation){
        if(event.wheelDelta<0){
          this.actualView++;
          if(this.actualView > this.totalViews){
            this.actualView = this.totalViews;
          }
        }
        else if(event.wheelDelta>0){
          this.actualView--;
          if(this.actualView < 0){
            this.actualView = 0;
          }
        }
        this.inAnimation=true;
        this.goToView(this.actualView);
        setTimeout(() => {
          this.inAnimation = false;
        }, 2250);
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.window.matchMedia('(min-width: 960px)').matches) {
      var left = $("#cover_project").width() * this.actualView;
      $("#home #cover_project, #home .other_cover").css({
        transform: "translate("+(-left)+"px, 0)",
        transition: 'transform 0s linear'
      });
      this.actualView = 0;
      this.goToView(0);
      this.slideshowIterator();
    } else{
      var height = $("#cover_project").height() * this.actualView;
      $("#home #cover_project, #home .other_cover").css({
        transform: "translate(0, "+(-height)+"px)",
        transition: 'transform 0s linear'
      });
      this.actualView = 0;
      this.inAnimation = false;
      this.goToView(0);
    }
  }

  ngOnInit() {
    if(this.dataService.getProjects() == null){
        this.getProjects();
    }else{
      this.projects = this.dataService.getProjects();
      this.loadingProjects = false;
      //LINHA NOVA
      this.dataService.processProjects();

      this.setProjects();
    }
    this.dataService.setNavBarColorWhite();
  }
  
  getProjects(){
    this.eventosService.getEventos().subscribe(projects => {
      this.dataService.setProjects(projects);
      this.projects = this.dataService.getProjects();
      this.loadingProjects = false;
      //LINHA NOVA
      this.dataService.processProjects();
      this.setProjects();
    });
  }



  setProjects(){
    for (let it = 0; it < this.projects.length; it++) {
      const project = this.projects[it];
      if(project.isCover == 'home'){
        this.coverProject = this.projects[it];
      }
      if(project.isCover == 'feedback'){
        this.feedbackProject = this.projects[it];
      }
      if(project.isCover == 'projects'){
        this.projectsProject = this.projects[it];       
      }
      if(project.isCover == 'contacts'){
        this.contactsProject = this.projects[it];       
      }
      if(project.isCover == 'about'){
        this.aboutProject = this.projects[it];       
      }
    }
    if(this.coverProject == null){
      this.coverProject = this.projects[0];
    }
    if(this.feedbackProject == null){
      this.feedbackProject = this.projects[0];
    }
    if(this.projectsProject == null){
      this.projectsProject = this.projects[0];
    }
    if(this.contactsProject == null){
      this.contactsProject = this.projects[0];
    }
    if(this.aboutProject == null){
      this.aboutProject = this.projects[0];
    }
    this.slideshowIterator();
  }

  slideshowIterator(){
    clearInterval(this.myInterval);
    if(this.isFirst){
      this.myInterval = setInterval(() => {
        if(this.window.matchMedia('(min-width: 960px)').matches) {
          this.actualView++;
          if(this.actualView > this.totalViews){
            this.actualView = 0;
          }
          this.inAnimation=true;
          this.goToView(this.actualView);
          clearInterval(this.myInterval);
          this.isFirst = false;
          this.slideshowIterator();
          setTimeout(() => {
            this.inAnimation = false;
          }, 8000);
        }
      },8000);
    } else {
      this.myInterval = setInterval(() => {
        if(this.window.matchMedia('(min-width: 960px)').matches) {
          this.isFirst = false;
          this.actualView++;
          if(this.actualView > this.totalViews){
            this.actualView = 0;
          }
          this.inAnimation=true;
          this.goToView(this.actualView);
          setTimeout(() => {
            this.inAnimation = false;
          }, 6000);
        }
      },6000);
    }   
  }
  
  changeView(evt){
    if(!this.inAnimation){
      const direction = Math.abs(evt.deltaY) > 40 ? (evt.deltaY > 0 ? 0 : 1) : '';
      if(direction == 0){
        this.actualView--;
        if(this.actualView < 0){
          this.actualView = 0;
        }
      } else {
        this.actualView++;
        if(this.actualView > this.totalViews){
          this.actualView = this.totalViews;
        }
      }
      this.goToView(this.actualView);
      this.inAnimation = true;
      setTimeout(() => {
        this.inAnimation = false;
      }, 700);
    }
  }

  goToView(view){
    if (this.window.matchMedia('(min-width: 960px)').matches) {
      var left = $("#cover_project").width() * view;
      $("#home #cover_project, #home .other_cover").css({
        transform: "translate("+(-left)+"px, 0)",
        transition: 'transform 1.4s cubic-bezier(0.87,0,0.13,1)'
      });
    } else{
      var height = $("#cover_project").height() * view;
      $("#home #cover_project, #home .other_cover").css({
        transform: "translate(0, "+(-height)+"px)",
        transition: 'transform 1.4s cubic-bezier(0.87,0,0.13,1)'
      });
    }
  }

  goTo(page){
    if(page == 'feedback' && this.actualView == 1){
      this.router.navigate(["/"+page+"/"]);
    }
    else if(page == 'projects' && this.actualView == 2){
      this.router.navigate(["/"+page+"/"]);
    }
    else if(page == 'contacts' && this.actualView == 3){
      this.router.navigate(["/"+page+"/"]);
    }
    else if(page == 'about' && this.actualView == 4){
      this.router.navigate(["/"+page+"/"]);
    }
  }

  getImgCover(project){
    if (this.window.matchMedia('(min-width: 960px)').matches) {
      let url = 'url('+this.dataService.baseUrl+'/imagens/'+project.background.file +')'; 
      return url;
    } else {
      let url = 'url('+this.dataService.baseUrl+'/imagens/'+project.background_mobile.file +')'; 
      return url;
    }
  }


}
