import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { FeedbackService } from '../../services/feedback.service';

declare var $: any;

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  loadingFeedback = true;
  selectedDate = 0;
  constructor(
    public dataService: DataService,
    public feedbackService: FeedbackService ) { }

    ngOnInit() {
      if(this.dataService.getFeedback() == null){
        this.getFeedback();
      }else{
        this.loadingFeedback = false;
        this.changeYear(this.selectedDate);
      }
    }

    getFeedback(){
      this.feedbackService.getFeedback().subscribe(data => {
        this.dataService.setFeedback(data);
        this.changeYear(this.selectedDate);
        this.loadingFeedback = false;
      });
    }

    changeYear(i){
      $(".feed_desktop").css('opacity', 0);
      setTimeout(() => {
        this.selectedDate = i;
        setTimeout(() => {
          $(".feed_desktop").css('opacity', 1);  
        }, 100);
      }, 300);
    }

    getLeftPos(i){
      var inc = (i * (100 / this.dataService.getFeedback()['keys'].length));
      var container_width = $("#feedback_container_desktop").width();
      var final_inc = inc*container_width/100;
      if(final_inc >  i * 100){
        final_inc = i * 100;
      }
      var final_measure = 100 + final_inc;
      return final_measure +"px";
    }

}
