import { Injectable, Inject } from '@angular/core';
import { environment } from '../../environments/environment';
import { Router } from "@angular/router";
import { WINDOW } from '@ng-toolkit/universal';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public feedback = null;
  public projects = null;
  public categories = null;
  public height = null;
  public baseUrl = environment.baseUrl;

  constructor(@Inject(WINDOW) private window: Window, public router: Router) { }

  redirectURL(url){
    
    if((this.router.url + '/').includes(url)){
      console.log("aqui");
      return;
    }
    const producao = false;
    var myURL = producao ? ("/fpa/frontend"+url+"/") : (url+"/");
    const _self = this;
    $(".page_content").removeClass("visible");
    setTimeout(() => {   
      this.router.navigate([myURL]);
    }, 600);    
  }

  getCategories(){
    return this.categories;
  }

  setCategories(data){
    this.categories = data;
  }

  getFeedback(){
    return this.feedback;
  }

  setFeedback(data){
    this.feedback = [];
    this.feedback['raw'] = data;
    this.feedback['groupedByYear'] =  this.groupBy(data, (feed) => feed.data);
    this.feedback['keys'] =  Object.keys(this.feedback['groupedByYear']).reverse();
  }

  groupBy(xs, f) {
    return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
  }

  getProjects(){
    return this.projects;
  }

  setProjects(projects){
    this.projects = projects;
    this.projects.forEach(project => {
      project.visible = true;
     
      for (let it = 0; it < project.images.length; it++) {
        const imgCandidate = project.images[it];
        if(imgCandidate.cover == "1"){
          project.background = imgCandidate;
        }
        if(imgCandidate.cover_mobile == "1"){
          project.background_mobile = imgCandidate;
        }
      }
    });
  }

  //
  processProjects(){
    this.projects.forEach(project => {
      let imagens = project['images'];
      let coverImg;
      for (let it = 0; it < imagens.length; it++) {
        const imagem = imagens[it];
        if(imagem.cover == "1"){
          coverImg = imagem;
          imagens.splice(it, 1);
          break;
        }
      }
      // imagens.unshift(coverImg);
      this.projects['images'] = imagens;

    });
  }
  setHeight(){
    let tempHeight = 0;
    if( typeof( this.window.innerWidth ) == 'number' ) {
      tempHeight = this.window.innerHeight;
    } else if( document.documentElement && ( document.documentElement.clientWidth || document.documentElement.clientHeight ) ) {
      tempHeight = document.documentElement.clientHeight;
    } else if( document.body && ( document.body.clientWidth || document.body.clientHeight ) ) {
      tempHeight = document.body.clientHeight;
    }
    this.height = tempHeight;
    $("html, body").css("height", this.height + "px");
  }

  getSize(){
    return this.height + "px";
  }
  
  setNavBarColorBlack(){
    if(!$("#logo img").hasClass('dark')){
      $("#logo img").addClass('dark');
    }
    $("#navbar_mobile").css('color', 'black');
    $("#menu_icon span").css('background-color', 'black');
    $("#navbar_desktop").css('color', 'black');
  }


  setBodyGrey(){
    $("body").css({
      "background-color":"#f1f1f1"
    });
  }
  setBodywhitee(){
    $("body").css({
      "background-color":"#ffffff"
    });
  }
  setBodyBlack(){
    $("body").css({
      "background-color":"black"
    });
  }
  setNavBarColorWhite(){
    if($("#logo img").hasClass('dark')){
      $("#logo img").removeClass('dark');
    }
    $("#navbar_mobile").css('color', 'white');
    $("#menu_icon span").css('background-color', 'white');
    $("#navbar_desktop").css('color', 'white');
  }

  showHiddenText(letter){
    $("#"+letter+"_hidden_text").addClass('display');
  }

  hideHiddenText(){
    $(".hidden_text").removeClass('display');
  }

  breakLogoProjects(){
    $("#dash-container, #a-container").addClass('break');
  }

  removeBreakLogoProjects(){
    $("#dash-container, #a-container").removeClass('break');
  }

  showContent(selector){
    setTimeout(() => {
      $(selector).css('opacity','1');  
    }, 0);
  }
}